import React, { useState, useEffect } from "react";
import Button from "../../../components/shared/Button/Button";
import InputField from "../../../components/shared/InputField/InputField";
import * as API from "../../../api/Api";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useAlertModalContext } from "../../../lib/context/AlertModalContext/AlertModalContext";
import { checkIfObjectHasChanged } from "../../../lib/helpers/checkIfObjectHasChanged";
import "./EditOrganization.scss";

const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,63}(\.[a-zA-Z]{2,63})?$/;

const EditOrganization = () => {
  const authCtx = useAuthContext();
  const openMessageModal = useAlertModalContext().openMessageModal;

  const [isLoading, setIsLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [id, setId] = useState("");
  const [address, setAddress] = useState("");
  const [allowedDomain, setAllowedDomain] = useState("");
  const [errorDomain, setErrorDomain] = useState("");
  const [errorAddress, setErrorAddress] = useState("");

  const selectedOrg = JSON.parse(localStorage.getItem("selectedOrganization")!);

  
  useEffect(() => {

    if (selectedOrg && id !== selectedOrg.id) { 
      setAddress(selectedOrg.address || "");
      setAllowedDomain(selectedOrg.allowed_domain || "");
      setId(selectedOrg.id);
    }
  }, [selectedOrg]);

  useEffect(() => {

    if (selectedOrg) {
      const oldValues = {
        address: selectedOrg.address || "",
        allowed_domain: selectedOrg.allowed_domain || "",
      };
      const newValues = {
        address,
        allowed_domain: allowedDomain,
      };
      const changed = checkIfObjectHasChanged(oldValues, newValues);

      setFormChanged(changed);
    }
  }, [address, allowedDomain]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedOrg) return;

    setErrorDomain("");
    setErrorAddress("");

    if (!address.trim()) {
      setErrorAddress("Address cannot be empty.");
      return;
    }
    if (!allowedDomain.trim()) {
      setErrorDomain("Domain cannot be empty.");
      return;
    }

    if (!domainRegex.test(allowedDomain.trim())) {
      setErrorDomain("Please enter a valid domain, e.g. mysite.com");
      return;
    }

    try {
      
      setIsLoading(true);
      const data = {
        address: address.trim(),
        allowed_domain: allowedDomain.trim(),
      };
      await API.updateOrganization(selectedOrg.id, data);

      const updatedOrg = {
        ...selectedOrg,
        address: data.address,
        allowed_domain: data.allowed_domain,
      };
      localStorage.setItem("selectedOrganization", JSON.stringify(updatedOrg));
      authCtx.setSelectedOrganization(updatedOrg);

      openMessageModal("success", "Organization updated successfully!");
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      openMessageModal(
        "error",
        err?.response?.data?.error ||
          "An error has occurred, please try again!"
      );
    }
  };

  return (
    <div className="EditOrganization">
      <div className="EditOrganization__container">
        <h2 className="EditOrganization__title">Edit Organization</h2>

        <form onSubmit={handleSubmit}>
          <div className="EditOrganization__form-group">
            <label className="EditOrganization__label">Address</label>
            <InputField
              type="text"
              onBlur={() => {}}
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              name="address"
            />
            {errorAddress && (
              <div className="EditOrganization__error">{errorAddress}</div>
            )}
          </div>

          <div className="EditOrganization__form-group">
            <label className="EditOrganization__label">Allowed Domain</label>
            <InputField
              type="text"
              onBlur={() => {}}
              onChange={(e) => {console.log("ee" , e.target.value); setAllowedDomain(e.target.value)}}
              value={allowedDomain}
              name="allowedDomain"
            />
            {errorDomain && (
              <div className="EditOrganization__error">{errorDomain}</div>
            )}
          </div>

          <div className="EditOrganization__buttons">
            <Button
              label="Save"
              onClick={() => {}}
              type="submit"
              isLoading={isLoading}
              className={formChanged ? "Button__primary" : "Button--disabled"}
              disabled={!formChanged || isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditOrganization;
