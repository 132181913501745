import React from "react";
import { ReactNode } from "react";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import SignUp from "../pages/SignUp/SignUp";
import NewPassword from "../pages/Login/NewPassword/NewPassword";
import CreateOrganization from "../pages/Organizations/CreateOrganization/CreateOrganization";
import Organizations from "../pages/Organizations/Organizations";
import Webhooks from "../pages/Webhooks/Webhooks";
import Devices from "../pages/Devices/Devices";
import EmailVerification from "../pages/EmailVerification/EmailVerification";
import Profile from "../pages/Profile/Profile";
import NewWebhook from "../pages/Webhooks/NewWebhook/NewWebhook";
import WebhookLogs from "../pages/Webhooks/WebhookLogs/WebhookLogs";
import LogDetails from "../pages/Webhooks/LogDetails/LogDetails";
import SecureKey from "../pages/SecureKey/SecureKey";
import SecureKeyLogs from "../pages/SecureKey/SecureKeyLogs/SecureKeyLogs";
import EditOrganization from "../pages/Organizations/EditOrganization/EditOrganization";

export interface AppRoute {
  path: string;
  name?: string;
  element?: ReactNode;
  childRoute?: { path: string; name?: string; element?: ReactNode }[];
}

export const PrivateRoutes: AppRoute[] = [
  {
    path: "/",
    name: "Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/webhooks",
    name: "Webhooks",
    element: <Webhooks />,
  },
  {
    path: "/webhooks/add-webhook",
    name: "Add Webhook",
    element: <NewWebhook />,
  },
  {
    path: "/webhooks/webhook-logs",
    name: "Webhook logs",
    element: <WebhookLogs />,
  },
  {
    path: "/log-details",
    name: "Log details",
    element: <LogDetails />,
  },
  {
    path: "/devices",
    name: "Devices",
    element: <Devices />,
  },
  {
    path: "/secure-key",
    name: "Secure Key",
    element: <SecureKey />,
  },
  {
    path: "/secure-key/:path",
    name: "Secure Key Logs",
    element: <SecureKeyLogs />,
  },
  {
    path: "/profile",
    name: "My Profile",
    element: <Profile />,
  },
  {
    path: "/edit-organization",
    name: "Edit Organization",
    element: <EditOrganization />, 
  },
];

export const PublicRoutes: AppRoute[] = [
  {
    path: "/login",
    name: "Login",
    element: <Login />,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    element: <SignUp />,
  },
  {
    path: "/verify-email",
    name: "Email Verification",
    element: <EmailVerification />,
  },
  {
    path: "/new-password/:confirmToken?",
    name: "New Password",
    element: <NewPassword />,
  },
  {
    path: "/organizations",
    name: "My organizations",
    element: <Organizations />,
  },
  {
    path: "/create-organization",
    name: "Create your organization",
    element: <CreateOrganization />,
  },
];
